export const CONFIG = {
    backendUrl: getEnvVariable('REACT_APP_BACKEND_URL'),
    panelNames: [
        'master',
        'ssa',
    ],
    productionServer: getEnvVariable('REACT_APP_PRODUCTION_SERVER'),
};

function getEnvVariable(key: string): string {
    const value = process.env[key];
    if (!value) {
        throw new Error(`Missing required environment variable: ${key}`);
    }
    return value;
}
