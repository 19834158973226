import axios, {AxiosResponse} from 'axios';
import {CONFIG} from '../config';

export interface User {
    id: string;
    email: string;
    name: string;
    panelName: string;
    adminId: string;
    lastEmergencyChargeDate: Date;
    token: string;
    amount: number;
    expire: Date;
    used: number;
}

export interface UserSubscription {
    email: string;
    name: string;
    panelName: string;
    lastEmergencyChargeDate: Date;
    token: string;
    amount: number;
    expire: Date;
    used: number;
    onlineAt: Date | null;
    status: string;
    links: string[];
}

export class UserRestClient {
    private static uniqueInstance: UserRestClient;
    private url = CONFIG.backendUrl;
    
    static getUsersRestClient() {
        if (!this.uniqueInstance) {
            this.uniqueInstance = new UserRestClient();
        }
        
        return this.uniqueInstance;
    }
    
    async createUser(
        adminToken: string, email: string, name: string, panelName: string, productName: string,
    ): Promise<User> {
        let res: AxiosResponse;
        
        try {
            res = await axios.post(`${this.url}/users`, {
                email, name, panelName, productName,
            }, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error creating user');
        }
        
        return res.data as User;
    }
    
    async getUsers(adminToken: string): Promise<User[]> {
        let res: AxiosResponse;
        
        try {
            res = await axios.get(`${this.url}/users`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error getting users');
        }
        
        return res.data as User[];
    }
    
    async updateUser(adminToken: string, userId: string, name?: string, email?: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.put(`${this.url}/users/${userId}`, {
                name, email,
            }, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error updating user');
        }
        
        return res.data as User;
    }
    
    async chargeUser(adminToken: string, userId: string, productName: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.put(`${this.url}/users/${userId}/charge`, {
                productName,
            }, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error charging user');
        }
        
        return res.data as User;
    }
    
    async changePanel(adminToken: string, userId: string, panelName: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.put(`${this.url}/users/${userId}/change-panel`, {
                panelName,
            }, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error changing user panel');
        }
        
        return res.data as User;
    }
    
    async enableUser(adminToken: string, userId: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.put(`${this.url}/users/${userId}/enable`, {}, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error enabling user');
        }
        
        return res.data as {message: string, status: string};
    }
    
    async disableUser(adminToken: string, userId: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.put(`${this.url}/users/${userId}/disable`, {}, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error enabling user');
        }
        
        return res.data as {message: string, status: string};
    }
    
    /**
     * End Users
     */
    async userSubscription(userToken: string) {
        let res: AxiosResponse;
        
        try {
            res = await axios.get(`${this.url}/users/sub/${userToken}`);
        } catch (e: any) {
            throw new Error(e.response?.data?.message || 'Error getting user subscription');
        }
        
        return res.data as UserSubscription;
    }
}
